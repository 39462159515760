<template>
    <div class="InspectionCheckDetail">
        <CSBreadcrumb />
        <div class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>检查项</th>
                        <th>完成时间</th>
                        <th>是否异常</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(check,index) in checkDetailList" :key="index" :value="index">
                        <td>{{check.name}}</td>
                        <td>
                            {{check.checkinTime || '-'}}
                            <div class="allow-click" v-if="check.archives" @click="lookArchives(check.archives)">查看图片</div>
                        </td>
                        <td>{{check.isTimeout ? '是' : '否'}}</td>
                    </tr>
                </template>
            </CSTable>
        </div>
        <ViewImageModal />
    </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb"
import ViewImageModal from "@/components/ViewImageModal";

import {
    queryInspectItemLogUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";
export default {
    props: {
        id: Number,
    },
    components:{
        CSTable,
        CSBreadcrumb,
        ViewImageModal,
    },
    data(){
        return {
            checkDetailList:[],
        }
    },
    created(){
        this.$fly.post(queryInspectItemLogUrl,{
            pointLogId: this.id,
            regionId: this.$vc.getCurrentRegion().communityId,
            operatorId: this.$vc.getCurrentStaffInfo().id,
        })
        .then(res=>{
            console.log(res);
            this.checkDetailList = res.data;
        })
    },
    methods: {
        /**
         * 查看图片
         * @param {String} photos 图片地址数组字符串
         * */
        lookArchives(photos) {
            this.$vc.emit(this.$route.path, 'viewImageModal', 'view', JSON.parse(photos || []))
        }
    }
}
</script>

<style>

</style>
